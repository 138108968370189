import { AmplifyTheme } from "aws-amplify-react";

export const Container = {
  ...AmplifyTheme.container,
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fbfbfb",
};
export const FormContainer = {
  ...AmplifyTheme.formContainer,
};
export const FormSection = { ...AmplifyTheme.formSection };
export const FormField = { ...AmplifyTheme.formField };
export const SectionHeader = {
  ...AmplifyTheme.sectionHeader,
  backgroundColor: "#282828",
  color: "#fbfbfb",
};
export const SectionBody = { ...AmplifyTheme.sectionBody };
export const SectionFooter = { ...AmplifyTheme.sectionFooter };
export const SectionFooterPrimaryContent = { ...AmplifyTheme.sectionFooterPrimaryContent };

// Sign up section - we remove
export const SectionFooterSecondaryContent = {
  display: "none",
};
export const Input = { ...AmplifyTheme.input };
export const Button = { ...AmplifyTheme.button };
export const PhotoPickerButton = { ...AmplifyTheme.photoPickerButton };
export const PhotoPlaceholder = { ...AmplifyTheme.photoPlaceholder };
export const SignInButton = { ...AmplifyTheme.signInButton };
export const SignInButtonIcon = { ...AmplifyTheme.signInButtonIcon };
export const SignInButtonContent = { ...AmplifyTheme.signInButtonContent };
export const Strike = { ...AmplifyTheme.strike };
export const StrikeContent = { ...AmplifyTheme.strikeContent };
export const ActionRow = { ...AmplifyTheme.actionRow };
export const FormRow = { ...AmplifyTheme.formRow };

// Anchor elements
export const A = { ...AmplifyTheme.a };
export const Hint = { ...AmplifyTheme.hint };
export const Radio = { ...AmplifyTheme.radio };
export const Label = { ...AmplifyTheme.label };
export const InputLabel = { ...AmplifyTheme.inputLabel };
export const AmazonSignInButton = { ...AmplifyTheme.amazonSignInButton };
export const FacebookSignInButton = { ...AmplifyTheme.facebookSignInButton };
export const GoogleSignInButton = { ...AmplifyTheme.googleSignInButton };
export const OAuthSignInButton = { ...AmplifyTheme.oAuthSignInButton };
export const Toast = { ...AmplifyTheme.toast };
export const NavBar = { ...AmplifyTheme.navBar };
export const NavRight = { ...AmplifyTheme.navRight };
export const Nav = { ...AmplifyTheme.nav };
export const NavItem = { ...AmplifyTheme.navItem };
export const NavButton = { ...AmplifyTheme.navButton };

const MyTheme = {
  container: Container,
  formContainer: FormContainer,
  formSection: FormSection,
  formField: FormField,

  sectionHeader: SectionHeader,
  sectionBody: SectionBody,
  sectionFooter: SectionFooter,
  sectionFooterPrimaryContent: SectionFooterPrimaryContent,
  sectionFooterSecondaryContent: SectionFooterSecondaryContent,

  input: Input,
  button: Button,
  photoPickerButton: PhotoPickerButton,
  photoPlaceholder: PhotoPlaceholder,
  signInButton: SignInButton,
  signInButtonIcon: SignInButtonIcon,
  signInButtonContent: SignInButtonContent,
  amazonSignInButton: AmazonSignInButton,
  facebookSignInButton: FacebookSignInButton,
  googleSignInButton: GoogleSignInButton,
  oAuthSignInButton: OAuthSignInButton,

  formRow: FormRow,
  strike: Strike,
  strikeContent: StrikeContent,
  actionRow: ActionRow,
  a: A,

  hint: Hint,
  radio: Radio,
  label: Label,
  inputLabel: InputLabel,
  toast: Toast,

  navBar: NavBar,
  nav: Nav,
  navRight: NavRight,
  navItem: NavItem,
  navButton: NavButton,
};

export default MyTheme;
